*:focus {
  outline: none;
}

input {
  background: #f8f8f8;
  margin: 10px;
  border-style: none;
  position: relative;
  font: 400 23px CoreSans, Arial,  sans-serif;
  }
form {
  margin: 10px;
  border-style: none;
  position: relative;
  font: 400 23px CoreSans, Arial,  sans-serif;
  }
  


body {
  color: #fff;
  background: #111111;
  margin: 0;
  padding: 10px;
  text-align: center;
  font: 400 23px CoreSans, Arial,  sans-serif;
}

button {
  padding: 10px;
  border-radius: 5px;
  border-style: none;
  background: transparent;
  color: #000;
  cursor: pointer;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 2;
  color: #fff;
}

li {
  color: #fff;
}

a {
  text-decoration: none;
  color: #fff;
}

a:hover {
  color: #A5A5A5;
}

/*
button:hover {
  background: #d3d3d3;
}
/*

/*
button:active {
  background: #00ff00;
}
*/
